<template>
    <!-- ============================ Hero Banner  Start================================== -->
    <div class="hero-banner full jumbo-banner" style="background:#f4f9fd url(assets/img/bg2.png);">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-7 col-md-8">
                    <a href="#" class="header-promo light w-inline-block">
                        <div class="header-promo-text">Request a demo</div>
                    </a>
                    <h1>Vital information to help grow <span class="text-info">career.</span></h1>
                    <form class="search-big-form banner-search shadow mt-3">
                        <div class="row m-0">
                            <div class="col-lg-10 col-md-9 col-sm-12 p-0">
                                <div class="form-group">
                                    <i class="bi bi-search"></i>
                                    <input type="text" class="form-control b-0 b-r l-radius"
                                    placeholder="Article, tutorial title or Keywords">
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-12 p-0">
                                <button type="button" class="btn dark-3 full-width r-radius">Search</button>
                            </div>
                        </div>
                    </form>
                    <div class="featured-category dark">
                        <ul>
                            <li>Explore:</li>
                            <li><a href="search-job.html" data-toggle="tooltip" class="bg-secondary" data-original-title="Banking">Latest tutorials</a></li>
                            <li><a href="search-job.html" data-toggle="tooltip" class="bg-primary" data-original-title="Healthcare">Popular tutorials</a></li>
                            <li><a href="search-job.html" data-toggle="tooltip" class="bg-warning" data-original-title="Software">Accessment skills</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5 col-md-4">
                    <base-image
                        imgName="a-2.png"
                        imgGeo="home"
                        imgClass="img-fluid"
                        imgAlt="Hero"
                     >
                     </base-image>
                </div>

            </div>
        </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->


    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg">
        <div class="container" style="max-width:95%;">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">New releases for the week</div>
                                </div>
                                <div class="_tag785">
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shilpa Sheri</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">Why people choose listio for own properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-4">Health</a></li>
										<li><a href="#" class="blog-cates-list style-3">Business</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shaurya</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">List of benifits and impressive listeo services</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Banking</a></li>
										<li><a href="#" class="blog-cates-list style-5">Stylish</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

                        <!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

					</div>


                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section >
        <div class="container" style="max-width:95%;">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">Accessment knowledge</div>
                                </div>
                                <div class="_tag785">
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shilpa Sheri</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">Why people choose listio for own properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-4">Health</a></li>
										<li><a href="#" class="blog-cates-list style-3">Business</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shaurya</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">List of benifits and impressive listeo services</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Banking</a></li>
										<li><a href="#" class="blog-cates-list style-5">Stylish</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

                        <!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

					</div>


                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg">
        <div class="container" style="max-width:95%;">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">Popular on Sabenzi</div>
                                </div>
                                <div class="_tag785">
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shilpa Sheri</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">Why people choose listio for own properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-4">Health</a></li>
										<li><a href="#" class="blog-cates-list style-3">Business</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shaurya</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">List of benifits and impressive listeo services</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Banking</a></li>
										<li><a href="#" class="blog-cates-list style-5">Stylish</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

                        <!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

					</div>


                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section >
        <div class="container" style="max-width:95%;">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">Because you applied for Backend engineering</div>
                                </div>
                                <div class="_tag785">
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shilpa Sheri</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">Why people choose listio for own properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-4">Health</a></li>
										<li><a href="#" class="blog-cates-list style-3">Business</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shaurya</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">List of benifits and impressive listeo services</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Banking</a></li>
										<li><a href="#" class="blog-cates-list style-5">Stylish</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

                        <!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

					</div>


                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg">
        <div class="container" style="max-width:95%;">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">Because you have 3 interviews this week</div>
                                </div>
                                <div class="_tag785">
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-left"></i>
                                    </a>
                                    <a href="javascript:void(0);" onclick="openRightMenu()" class="eltio_buttons">
                                        <i class="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shilpa Sheri</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">Why people choose listio for own properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-4">Health</a></li>
										<li><a href="#" class="blog-cates-list style-3">Business</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Shaurya</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">List of benifits and impressive listeo services</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Banking</a></li>
										<li><a href="#" class="blog-cates-list style-5">Stylish</a></li>
									</ul>
								</div>

							</div>
						</div>

						<!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

                        <!-- Single blog Grid -->
						<div class="col-lg-3 col-md-3 col-sm-12">
							<div class="blog-wrap-grid">

								<div class="blog-thumb">
									<a href="blog-detail.html"><img src="https://via.placeholder.com/600x400" class="img-fluid" alt=""></a>
								</div>

								<div class="blog-info">
									<span class="post-date">By Admin K.</span>
								</div>

								<div class="blog-body">
									<h4 class="bl-title"><a href="blog-detail.html">What people says about listio properties</a></h4>
								</div>

								<div class="blog-cates">
									<ul>
										<li><a href="#" class="blog-cates-list style-1">Fashion</a></li>
										<li><a href="#" class="blog-cates-list style-2">Wedding</a></li>
									</ul>
								</div>

							</div>
						</div>

					</div>


                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

</style>
